import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";

import logo from './assets/img/logo_large.png';

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="https://metafic.co" target="_blank">
<img src={logo} style={{height:"30px"}} />

            </a>
          </div>
          <div className="social">
            
            <a href="https://github.com/metafic-co" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>A revolutionary team working on path breaking ideas.</h1>
            <p>
              A group of outstanding individuals, handpicked for deftness in their realm.<br/> 
              A crew with proficiency in varied pursuits of tech. A team, just as dazzling as the projects it works on.<br/> 

              Passion. Engineered.
            </p>
         
          </div>
          <a href="">
            <div className="cta">Coming soon!</div>
          </a>
        </div>
        <div className="footer">
  <span> Developed by Tea<span style={{color:"red",}}>M</span> at Dev Center, India</span>
        </div>
      </div>
    );
  }
}

export default App;